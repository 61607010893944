import { Button, Group, Modal, Text } from "@mantine/core";
import React from "react";

export default function YesterdayPopup({ dict, closePopup }) {
	return (
		<Modal opened={true} onClose={closePopup} withCloseButton={false} >
			<Text weight={700} mb="xl">{dict.missYesterday1}</Text>
			<Text>{dict.missYesterday2}</Text>
			<Group position="center" mt="xl">
				<Button variant="outline" onClick={closePopup}>{dict.noThanks}</Button>
				<Button onClick={() => window.location.href = "https://bandle.app/store.html"}>{dict.yesPlease}</Button>
			</Group>
		</Modal>
	);
}